.main {
  background: url("building.jpeg") !important;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  overflow: hidden;
}

img {
    max-width: 100%;
    height: auto; 
}
